export default {
    storeUsers: {},
    boostedTour: {},
    storeFlags: {},
    notif_arr: [],
    counterNot: 0,
    message_arr: [],
    url_to_go: "http://localhost:8080/profile",
    tournamentSearch: "",
    countrySearch: "",
    continentsChecked: [],
    countriesChecked: [],
    TCChecked: [],
    DateMonthChecked: [],
    DateYearChecked: [],
    titleselected2: [],
    titleselected3: [],
    agefrom: "",
    ageto: "",
    ratingfrom: "",
    ratingto: "",
    searchtown: "",
    sexon: "",
    interestedclub: "",
    interestedtour: "",
    interestedevent: "",
    countriesChecked2: [],
    input_text: "",
    role: [],
    clickside1: false,
    countrySearchINT: "",
    activeOrg: "",
    verified: "",
    current_user: [],
    message_info: [],
    reload: false,
    my_blogs: [],
    feeds: [],
    dateFeeds: "",
    scrollTopDOM: "",
    scrollTopCalendar: "",
    feed: "",
    blog_feed: "",
    calendar: false,
    unreadMessage: false,
    notificationPayload: [],
    tournaments: [],
    rola: "",
    rolaPublic: "",
    onlinePayload: {},
    feedStartNumber: "",
    blogActive: false,
    myConnectionsFollowers: [],
    myConnectionsFollowing: [],
    listFollowingUuid: [],
    followTablePosition: 0,
    uploaded_image: "",
    pageNumberPagination: "",
    blog_feed_profile: "",
    connectionDownloaded: false,
    walletPosition: "walletActive",
    walletSum: "",
    trainer_price: "",
    datumCalendar: "",
    calendarActive: false,
    numberGroupNow: "",
    notification_loaded: false,
    notify_array: [],
    notify_array_challange: [],
    interestedPlayer: false,
    interestedClub: false,
    myChallenges: [],
    popupOnSignIn: false,
    enterDataFIDE: false,
    messages_contact_list: [],
};
